/*login.js css 코드*/

@media (max-width: 768px) { 
  .responsive-input {
    width: 90% !important; 
  }
}

/*sign.js css 코드*/

.register-container {
    background-color: #f6f6f6;
    padding: 50px 0;
  }
  
  .register-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .register-form {
    background-color: #ffffff;
    padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .register-submit-btn {
    width: 100%;
  }
  
  /*noticeboardwrite.js css 코드*/

  .ck-editor__editable {
    height: 400px;
  }

  /*carregister.js css 코드*/
  .ant-table-thead .ant-table-cell {
    text-align: center !important;
  }
  
  .news-table .ant-pagination {
    text-align: center;
    margin: 20px 0;
  }
  
  /* noticepage.js css 코드*/
  img {
    max-width: 80%;
    width: auto;
    height: auto;
    overflow-clip-margin: content-box;
    overflow: clip;
  }
  
  
